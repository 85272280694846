// Configs form validation

import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  call_radius_one: yup.number().required('Câmp obligatoriu').min(1, 'Minim 1 metru').max(65535, 'Distanță prea mare'),
  call_radius_two: yup
    .number()
    .required('Câmp obligatoriu')
    .min(yup.ref('call_radius_one'), 'Al doilea perimetru trebuie să fie mai mare decât primul')
    .max(65535, 'Distanță prea mare'),
  call_radius_one_night: yup
    .number()
    .required('Câmp obligatoriu')
    .min(1, 'Minim 1 metru')
    .max(65535, 'Distanță prea mare'),
  call_radius_two_night: yup
    .number()
    .required('Câmp obligatoriu')
    .min(yup.ref('call_radius_one_night'), 'Al doilea perimetru trebuie să fie mai mare decât primul')
    .max(65535, 'Distanță prea mare'),
  night_start_hour: yup
    .number()
    .integer('Oră invalidă')
    .required('Câmp obligatoriu')
    .min(0, 'Oră invalidă')
    .max(23, 'Oră invalidă'),
  night_end_hour: yup
    .number()
    .integer('Oră invalidă')
    .required('Câmp obligatoriu')
    .min(0, 'Oră invalidă')
    .max(23, 'Oră invalidă'),
  max_cars_on_order: yup.number().required('Câmp obligatoriu').min(1, 'Minim 1 mașină').max(255, 'Maxim 255 mașini'),
  seconds_wait_driver_response: yup
    .number()
    .required('Câmp obligatoriu')
    .min(5, 'Minim 5 secunde')
    .max(255, 'Maxim 255 secunde'),
  receive_notifications_email: yup
    .string()
    .required('Câmp obligatoriu')
    .email('Email invalid')
    .max(255, 'Lungime maximă 255 caractere'),
  drivers_logout_time_seconds_log: yup
    .number()
    .required('Câmp obligatoriu')
    .min(0, 'Minim 0 secunde')
    .max(65000, 'Maxim 65000 secunde')
});
