// React
import React, { useState, useMemo, useCallback } from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

// Utils
import { useFetch } from '../../../utils/request';
import clsx from 'clsx';
import moment from 'moment';

// Constants
import { BACKEND_URL, DATE_TIME_FORMAT } from '../../../constants';

// Components
import OverlayLoader from '../../Loader/OverlayLoader';
import SearchField from '../../Form/SearchField';
import TablePaginationActions from '../../TablePaginationActions';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  tableContainer: {
    width: '100%'
  },
  table: {
    minWidth: 650
  },
  cell: {
    padding: theme.table.cellPadding,
    wordBreak: 'break-all'
  },
  headCell: {
    backgroundColor: theme.table.headerBackground,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    whiteSpace: 'nowrap'
  },
  paginationToolbar: {
    overflow: 'hidden',
    minHeight: 52
  },
  dateCell: {
    width: 200
  }
}));

const LogoutsList = () => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(+localStorage.getItem('rowsPerPage') || 10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const { loading, data } = useFetch(`${BACKEND_URL}/drivers/logs?page=${page}&limit=${rowsPerPage}&q=${search}`);

  const columns = useMemo(
    () => [
      { id: 'driver_username', label: 'Șofer' },
      { id: 'indicative', label: 'Indicativ' },
      {
        id: 'updated_at',
        label: 'Timp delogat',
        format: (_, { logged_in_at, created_at }) => {
          const seconds = moment(logged_in_at).diff(created_at, 'seconds');
          const minutes = parseInt(seconds / 60, 10);

          return minutes ? `${minutes} minute ${seconds % 60} secunde` : `${seconds} secunde`;
        }
      },
      {
        id: 'logged_in_at',
        label: 'Dată logare',
        cls: classes.dateCell,
        format: (date) => moment(date).format(DATE_TIME_FORMAT)
      },
      {
        id: 'created_at',
        label: 'Dată delogare',
        cls: classes.dateCell,
        format: (date) => moment(date).format(DATE_TIME_FORMAT)
      }
    ],
    [classes]
  );

  const handleChangePage = useCallback(
    (_, newPage) => {
      setPage(newPage + 1);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      localStorage.setItem('rowsPerPage', +event.target.value);
      setRowsPerPage(+event.target.value);
      setPage(1);
    },
    [setRowsPerPage, setPage]
  );

  return (
    <Paper classes={{ root: classes.paper }}>
      {loading && <OverlayLoader />}

      <Box display="flex" p={0.5} alignItems="center" flexWrap="wrap">
        <SearchField
          className={classes.search}
          handleSearch={(val) => {
            setSearch(val);
            setPage(1);
          }}
        />
      </Box>

      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader classes={{ root: classes.table }}>
          <TableHead>
            <TableRow>
              {columns.map(({ id, cls, label, format, children, ...rest }) => (
                <TableCell key={id} classes={{ root: clsx(classes.cell, cls), head: classes.headCell }} {...rest}>
                  {children || label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data &&
              data.data.map((logData) => (
                <TableRow key={logData.id}>
                  {columns.map(({ id, format, cls }) => {
                    const value = logData[id];

                    return (
                      <TableCell key={id} classes={{ root: clsx(classes.cell, cls) }}>
                        {format ? format(value, logData) : value || '-'}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        labelRowsPerPage="Rânduri pe pagină:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} din ${count}`}
        classes={{ root: classes.paginationToolbar }}
        component="div"
        count={data ? data.total : 0}
        rowsPerPage={rowsPerPage}
        page={data ? page - 1 : 0}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Paper>
  );
};

export default LogoutsList;
