// React
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

// Material UI
import { makeStyles } from '@material-ui/core/styles';

// Components
import AppHeaderBar from '../../components/Core/AppBar';
import UsersList from './UsersList';
import ClientsList from './ClientsList';
import LogoutsList from './LogoutsList';
import Addresses from './Addresses';
import OrdersHistory from './Orders/OrdersHistory';
import DispatcherDashboard from './DispatcherDashboard';
import AdminDashboard from './AdminDashboard';
import ConfigForm from './ConfigForm';
import Statistics from './Statistics';
import ErrorBoundary from '../ErrorBoundary';
import SocketProvider from '../Providers/SocketProvider';
import FullscreenMap from './Map/FullscreenMap';
import DemoCalls from './DemoCalls';
import OrderNotTakenNotifications from './OrderNotTakenNotifications';

// Constants
import {
  DASHBOARD_ROUTE,
  ADMIN_DASHBOARD_ROUTE,
  ORDERS_HISTORY_ROUTE,
  STATISTICS_ROUTE,
  USERS_ROUTE,
  MAP_ROUTE,
  SETTINGS_ROUTE,
  CLIENTS_ROUTE,
  LOGOUTS_ROUTE,
  ADDRESSES_ROUTE,
  DEMO_CALLS_ROUTE
} from '../Routes/constants';
import {
  MANAGE_CONFIG_PERMISSION,
  MANAGE_USERS_PERMISSION,
  READ_USERS_PERMISSION,
  READ_STATISTICS_PERMISSION,
  ADMIN_PERMISSION,
  checkPermissions
} from '../../utils/permissions';
import { BACKEND_URL, TOKEN_REFRESH_INTERVAL } from '../../constants';

// Utils
import { AuthContext } from '../../context';
import LocationProvider from '../Providers/LocationProvider';
import request from '../../utils/request';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.type === 'dark' ? '#121212 !important' : '#f5f5f5 !important'
    },

    '::-webkit-scrollbar': {
      width: 8,
      height: 8
    },

    '::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 3px rgba(0,0,0,0.3)',
      borderRadius: 8,
      background: theme.palette.type === 'dark' ? '#2e2e2e' : '#e3e3e3'
    },

    '::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      background: '#b5b5b5',
      '-webkit-box-shadow': 'inset 0 0 3px rgb(0,0,0)'
    }
  },
  /* used to create a fake container under the toolbar - which is fixed */
  fakeToolbar: {
    ...theme.mixins.toolbar
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    padding: theme.spacing(1),
    overflowX: 'hidden',
    overflowY: 'auto',
    flex: 1
  }
}));

let routes = [
  {
    path: STATISTICS_ROUTE,
    component: Statistics,
    permissions: [READ_STATISTICS_PERMISSION]
  },
  {
    path: ORDERS_HISTORY_ROUTE,
    component: OrdersHistory
  },
  {
    path: USERS_ROUTE,
    component: UsersList,
    permissions: [READ_USERS_PERMISSION, MANAGE_USERS_PERMISSION]
  },
  {
    path: CLIENTS_ROUTE,
    component: ClientsList
  },
  {
    path: LOGOUTS_ROUTE,
    component: LogoutsList
  },
  {
    path: ADDRESSES_ROUTE,
    component: Addresses,
    permissions: [ADMIN_PERMISSION]
  },
  {
    path: MAP_ROUTE,
    component: FullscreenMap
  },
  {
    path: SETTINGS_ROUTE,
    component: ConfigForm,
    permissions: [MANAGE_CONFIG_PERMISSION]
  },
  {
    path: DASHBOARD_ROUTE,
    component: DispatcherDashboard
  },
  {
    path: ADMIN_DASHBOARD_ROUTE,
    component: AdminDashboard,
    permissions: [ADMIN_PERMISSION]
  }
];

if (process.env.REACT_APP_ENV === 'dev') {
  routes.push({
    path: DEMO_CALLS_ROUTE,
    component: DemoCalls
  });
}

const Core = () => {
  const classes = useStyles();

  const { permissions } = useContext(AuthContext);

  const tokenRefreshInterval = useRef(null);

  const refreshToken = useCallback(() => {
    request(`${BACKEND_URL}/user/refresh-token`)
      .then((token) => {
        localStorage.setItem('token', token);
      })
      .catch(() => {
        // token refresh failed
        if (tokenRefreshInterval.current) {
          clearInterval(tokenRefreshInterval.current);
        }
      });
  }, []);

  useEffect(() => {
    refreshToken();

    if (!tokenRefreshInterval.current) {
      tokenRefreshInterval.current = setInterval(() => {
        refreshToken();
      }, TOKEN_REFRESH_INTERVAL);

      return () => {
        if (tokenRefreshInterval.current) {
          clearInterval(tokenRefreshInterval.current);
        }
      };
    }
  }, [refreshToken]);

  return (
    <SocketProvider>
      <LocationProvider>
        <AppHeaderBar />

        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <OrderNotTakenNotifications />
        </SnackbarProvider>

        <div className={classes.root}>
          <div className={classes.fakeToolbar}></div>

          <main className={classes.content}>
            <ErrorBoundary>
              <Switch>
                {routes.map(({ permissions: routePermissions, ...props }) => {
                  if (routePermissions && !checkPermissions(permissions, routePermissions)) {
                    return null;
                  }

                  return <Route key={props.path} exact {...props} />;
                })}

                <Redirect to={DASHBOARD_ROUTE} />
              </Switch>
            </ErrorBoundary>
          </main>
        </div>
      </LocationProvider>
    </SocketProvider>
  );
};

export default Core;
