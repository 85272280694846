// Inactive orders list

// React
import React, { useContext, useEffect, useMemo, useState } from 'react';

// Material UI
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';

// Utils
import clsx from 'clsx';
import { SocketContext } from '../../../context';
import moment from 'moment';
import { secondsToMinutes, emitInfoByRadius } from '../../../utils/formatters';

// Components
import StatusCell from '../Orders/StatusCell';
import OriginIcon from '../Orders/OriginIcon';
import IndicativeEditDialog from '../Orders/IndicativeEditDialog';

// Constants
import { PENDING, ACCEPTED, COMPLETED, CANCELED } from '../../Routes/constants';
import { DATE_TIME_FORMAT, CANCELED_DRIVER } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: '100%',
    maxHeight: '100%'
  },
  table: {
    minWidth: 650
  },
  cell: {
    padding: theme.table.cellPadding,
    maxWidth: 300,
    wordBreak: 'break-all'
  },
  [CANCELED_DRIVER]: {
    color: theme.statusPalette[CANCELED_DRIVER]
  },
  [CANCELED]: {
    color: theme.statusPalette[CANCELED]
  },
  [ACCEPTED]: {
    color: theme.statusPalette[ACCEPTED]
  },
  [COMPLETED]: {
    color: theme.statusPalette[COMPLETED]
  },
  [PENDING]: {
    color: theme.statusPalette[PENDING]
  },
  dateCell: {
    width: 200
  },
  mediumCell: {
    width: 120
  },
  smallCell: {
    width: 60,
    textAlign: 'center',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`
  },
  headCell: {
    backgroundColor: theme.table.headerBackground,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    whiteSpace: 'nowrap'
  },
  actionBtn: {
    color: theme.statusPalette[PENDING],
    padding: 4
  },
  actionBtnCell: {
    paddingTop: 2,
    paddingBottom: 2
  }
}));

const columnLabels = {
  from_mobile_app: 'Origine',
  address: 'Adresa',
  details: 'Detalii',
  name: 'Client',
  phone: 'Telefon',
  nr_of_cars: 'Nr. mașini',
  indicative: 'Mașina',
  line: 'Linie',
  created_at: 'Data',
  nr_of_emits: 'Preluare',
  process_seconds: 'Timp procesare',
  status: 'Status'
};

const InactiveOrders = () => {
  const classes = useStyles();

  const [orders, setOrders] = useState([]);

  const [indicativeEditPopupProps, setIndicativeEditPopupProps] = useState(null);

  const { socket, connected } = useContext(SocketContext);

  const updateOrders = ({ other_orders }) => setOrders(other_orders);

  useEffect(() => {
    if (connected && socket) {
      socket.on('dispatchers.orders.overview.update', updateOrders);
    }

    return () => {
      if (connected && socket) {
        socket.off('dispatchers.orders.overview.update', updateOrders);
      }
    };
  }, [socket, connected]);

  const columns = useMemo(
    () => [
      {
        col: 'from_mobile_app',
        cls: classes.smallCell,
        format: (isMobile) => <OriginIcon isMobile={isMobile} />
      },
      { col: 'line', cls: classes.smallCell },
      {
        col: 'indicative',
        cls: clsx(classes.smallCell, classes.actionBtnCell),
        format: (indicative, { id, status, nr_of_cars }) => {
          if (status === CANCELED && !indicative) {
            return (
              <IconButton
                classes={{ root: classes.actionBtn }}
                onClick={() =>
                  setIndicativeEditPopupProps({
                    orderId: id,
                    nrOfCars: nr_of_cars
                  })
                }
              >
                <EditIcon />
              </IconButton>
            );
          }

          return indicative;
        }
      },
      { col: 'address' },
      { col: 'details' },
      { col: 'name' },
      { col: 'phone', cls: classes.mediumCell },
      { col: 'created_at', cls: classes.dateCell, format: (date) => moment(date).format(DATE_TIME_FORMAT) },
      {
        col: 'nr_of_emits',
        format: emitInfoByRadius
      },
      {
        col: 'process_seconds',
        cls: classes.smallCell,
        format: secondsToMinutes
      },
      {
        col: 'nr_of_cars',
        cls: classes.smallCell
      },
      {
        col: 'status',
        cls: classes.mediumCell,
        format: (status, { reason }) => <StatusCell status={status} reason={reason} />
      }
    ],
    [classes]
  );

  return (
    <>
      {indicativeEditPopupProps && (
        <IndicativeEditDialog onClose={() => setIndicativeEditPopupProps(null)} {...indicativeEditPopupProps} />
      )}

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map(({ col, cls, format, ...rest }) => (
                <TableCell key={col} classes={{ root: clsx(classes.cell, cls), head: classes.headCell }} {...rest}>
                  {columnLabels[col]}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                {columns.map(({ col, format, cls, ...rest }) => {
                  const value = order[col];

                  return (
                    <TableCell key={col} classes={{ root: clsx(classes.cell, classes[order.status], cls) }} {...rest}>
                      {format ? format(value, order) : value ?? '-'}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default InactiveOrders;
