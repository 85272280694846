// React
import React, { useCallback } from 'react';

// Material-ui
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

// Formik
import { Formik, Form } from 'formik';
import { validationSchema } from './validation';

// Utils
import request, { useFetch } from '../../../utils/request';
import { NotificationManager } from 'react-notifications';
import isEqual from 'lodash/isEqual';

// Constants
import { BACKEND_URL } from '../../../constants';

// Components
import CustomTextField from '../../Form/CustomTextField';
import OverlayLoader from '../../Loader/OverlayLoader';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3)
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  cancelBtn: {
    marginRight: 15
  },
  mapContainer: {
    minHeight: 600
  },
  map: {
    '& > div': {
      background: 'transparent !important'
    }
  }
}));

function ConfigForm() {
  const classes = useStyles();

  const { loading, data } = useFetch(`${BACKEND_URL}/config`);

  const { loading: driversListLoading, data: driversList } = useFetch(`${BACKEND_URL}/drivers/autocomplete`);

  const saveConfigs = useCallback(async (values, { resetForm }) => {
    try {
      const { priority_drivers, ...config } = values;
      await request(`${BACKEND_URL}/config`, {
        method: 'PUT',
        body: JSON.stringify({ config, priority_drivers })
      });

      resetForm({ values });

      NotificationManager.success(`Setările au fost salvate cu succes!`);
    } catch (ex) {
      NotificationManager.error(`A apărut o eroare ${ex.message}`);
    }
  }, []);

  if (loading || driversListLoading) {
    return <OverlayLoader fullScreen />;
  }

  const {
    config: { id, created_at, updated_at, ...configsData },
    priority_drivers
  } = data;

  return (
    <Paper classes={{ root: classes.paper }}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ ...configsData, priority_drivers }}
        onSubmit={saveConfigs}
      >
        {({ isValid, dirty, setFieldValue, isSubmitting, resetForm, values }) => (
          <Form>
            <Box pb={2} px={2} fontWeight="bold" fontSize={22}>
              Setări
            </Box>

            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomTextField
                      required
                      label="Perimetru 1 (m)"
                      type="number"
                      name="call_radius_one"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <CustomTextField
                      required
                      label="Perimetru 2 (m)"
                      type="number"
                      name="call_radius_two"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <CustomTextField
                      required
                      label="Perimetru 1 noapte (m)"
                      type="number"
                      name="call_radius_one_night"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <CustomTextField
                      required
                      label="Perimetru 2 noapte (m)"
                      type="number"
                      name="call_radius_two_night"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <CustomTextField
                      required
                      label="Oră început schimb noapte"
                      type="number"
                      name="night_start_hour"
                      inputProps={{ min: 0, max: 23 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <CustomTextField
                      required
                      label="Oră sfârșit schimb noapte"
                      type="number"
                      name="night_end_hour"
                      inputProps={{ min: 0, max: 23 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <CustomTextField
                      required
                      label="Secunde așteptare răspuns șofer"
                      type="number"
                      name="seconds_wait_driver_response"
                      inputProps={{ min: 5 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <CustomTextField
                      required
                      label="Număr maxim mașini / comandă"
                      type="number"
                      name="max_cars_on_order"
                      inputProps={{ min: 1 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <CustomTextField
                      required
                      label="Email notificări"
                      type="email"
                      name="receive_notifications_email"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <CustomTextField
                      required
                      label="Secunde istoric delogări"
                      type="number"
                      name="drivers_logout_time_seconds_log"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="priority_drivers"
                      options={driversList}
                      filterSelectedOptions
                      disableCloseOnSelect
                      value={values.priority_drivers}
                      getOptionLabel={({ indicative, username }) => `${indicative} - ${username}`}
                      getOptionSelected={(option, v) => isEqual(v, option)}
                      onChange={(e, value) => setFieldValue('priority_drivers', value)}
                      renderInput={(params) => <TextField {...params} margin="normal" label="Șoferi schimb noapte" />}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} classes={{ root: classes.buttonsContainer }}>
                <Button onClick={resetForm} color="secondary" classes={{ root: classes.cancelBtn }}>
                  Renunță
                </Button>

                <Button type="submit" color="primary" disabled={!dirty || isSubmitting || !isValid}>
                  Salvează
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
}

export default ConfigForm;
