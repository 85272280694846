// Drivers list

// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

// Utils
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: '100%',
    maxHeight: '100%'
  },
  clickableRow: {
    cursor: 'pointer'
  },
  selectedRow: {
    cursor: 'default',
    background: theme.palette.type === 'dark' ? '#646464' : '#efefef'
  },
  cell: {
    padding: theme.table.cellPadding,
    wordBreak: 'break-all'
  },
  headCell: {
    backgroundColor: theme.table.headerBackground,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    whiteSpace: 'nowrap'
  }
}));

const columns = [
  { col: 'indicative', label: 'Indicativ' },
  { col: 'username', label: 'Utilizator' },
  { col: 'nr_of_orders_in_shift', label: 'Nr. comenzi' }
];

const DriversList = ({ drivers, filterFromParent, setFilterFromParent }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map(({ col, label, cls }) => (
              <TableCell key={col} classes={{ root: clsx(classes.cell, cls), head: classes.headCell }}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {drivers.map((driver) => (
            <TableRow
              key={driver.id}
              classes={{
                root: clsx(
                  classes.clickableRow,
                  driver.indicative.toLowerCase() === filterFromParent.toLowerCase() && classes.selectedRow
                )
              }}
              onClick={() => setFilterFromParent(driver.indicative)}
            >
              {columns.map(({ col, format, cls }) => {
                const value = driver[col];

                return (
                  <TableCell key={col} classes={{ root: clsx(classes.cell, cls) }}>
                    {format ? format(value) : value || '-'}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DriversList.propTypes = {
  filterFromParent: PropTypes.string,
  setFilterFromParent: PropTypes.func.isRequired,
  drivers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      indicative: PropTypes.string.isRequired,
      is_penalized: PropTypes.bool.isRequired,
      penalization_starts_at: PropTypes.string.isRequired,
      penalization_ends_at: PropTypes.string.isRequired,
      nr_of_orders_in_shift: PropTypes.number.isRequired
    }).isRequired
  ).isRequired
};

export default DriversList;
